// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import "lazysizes";
import "lazysizes/plugins/bgset/ls.bgset.js";
import "lazysizes/plugins/native-loading/ls.native-loading";

document.addEventListener("lazybeforeunveil", (e) => {
    const IMG = e.target.getAttribute("data-bg");

    if (IMG) {
        e.target.style.backgroundImage = `url('${IMG}')`;
    }
});

function loadJS(u) {
    const r = document.getElementsByTagName("script")[0];
    const s = document.createElement("script");
    s.src = u;
    r.parentNode.insertBefore(s,r);
}

if (!window.HTMLPictureElement || !("sizes" in document.createElement("img"))) {
    loadJS("ls.respimg.min.js");
}
